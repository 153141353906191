<template>
    <div>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row>
                        <b-col md="4">
                            <b-form-group
                                label="Seleccionar Año"
                                label-for="year"
                            >
                                <v-select
                                    v-model="filtersForm.year"
                                    label="name"
                                    :options="years"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="4">
                            <b-form-group
                                label="Seleccionar Mes"
                                label-for="month"
                            >
                                <v-select
                                    v-model="filtersForm.month"
                                    label="name"
                                    :options="months"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group
                                label="Seleccionar Proveedor"
                                label-for="provider"
                            >
                                <v-select
                                    v-model="filtersForm.providers"
                                    label="name"
                                    multiple
                                    :options="providers"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="12">
                            <b-form-group
                                label="Seleccionar Puesto/Cargo"
                                label-for="position"
                            >
                                <v-select
                                    v-model="filtersForm.positions"
                                    label="name"
                                    multiple
                                    :options="positions"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    
                    <b-row>
                        <b-col sm="12" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                :block="true"
                                :disabled="btnFilterDisabled"
                                @click="filterData"
                            >                                    
                                <b-spinner small v-if="isLoading && btnFilterDisabled" />
                                <feather-icon v-if="!isLoading" icon="FilterIcon" size="1x"/>   
                                {{ isLoading && btnFilterDisabled ? 'FILTRANDO' : 'FILTRAR' }}
                            </b-button>
                            <hr>
                        </b-col>
                    </b-row>
                    
                    <div class="text-center text-primary my-2" v-if="isLoading">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <br>
                        <br>
                        <strong>OBTENIENDO REGISTROS</strong>
                    </div>

                    <template v-else>

                        <template v-if="items.length">

                            <b-card no-body class="card-salary-difference-item" v-for="(item, index) in items" :key="index">
                                <b-card-header>
                                    <h5 class="mb-0 text-white"><b>{{ item.proveedor }} ({{ (index + 1) }}/{{ items.length }})</b></h5>
                                </b-card-header>
                                <b-card-body class="pt-2">
                                    
                                    <b-row>
                                        <b-col cols="12" md="4">
                                            <json-excel :data="tableExport(item.proveedor, item.items, totals[index])" :name="getExcelName(item.proveedor, false)"></json-excel> 
                                        </b-col>
                                        <b-col cols="12" md="4">
                                            <json-excel :data="tableDetailsExport(item.proveedor, item.items_2, totals[index])" :name="getExcelName(item.proveedor, true)" buttonText="EXPORTAR EXCEL (DETALLADO)" ></json-excel> 
                                        </b-col>
                                        <b-col cols="12" md="4">
                                            <json-excel :data="tableGeneralExport(item.proveedor, item.items_3)" :name="getExcelName(item.proveedor, false, true)" buttonText="EXPORTAR EXCEL (GENERAL)" ></json-excel> 
                                        </b-col>
                                    </b-row>

                                    <b-table
                                        striped
                                        hover
                                        responsive
                                        class="position-relative text-center table-salary-difference mb-0"
                                        :per-page="item.items.length"
                                        :current-page="currentPage"
                                        :items="item.items"
                                        :fields="fields"
                                        :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc"
                                        :sort-direction="sortDirection"
                                    >

                                        <template #head()="data">
                                            <div v-html="data.label"></div>
                                        </template>

                                        <template #cell(salary)="data">
                                            <span class="d-block text-nowrap text-right">
                                                {{ data.item.salary_formatted }}
                                            </span>
                                        </template>

                                        <template #cell(tariff)="data">
                                            <span class="d-block text-nowrap text-right">
                                                {{ data.item.tariff_formatted }}
                                            </span>
                                        </template>

                                        <template #cell(difference_salary)="data">
                                            <span class="d-block text-nowrap text-right">
                                                {{ data.item.difference_salary_formatted }}
                                            </span>
                                        </template>

                                        <template #custom-foot>
                                            <tr>
                                                <td>{{ totals[index].enumeration }}</td>
                                                <td>{{ totals[index].role }} ({{ item.proveedor }})</td>
                                                <td>{{ totals[index].quantity }}</td>
                                                <td class="text-right">{{ totals[index].salary_formatted }}</td>
                                                <td class="text-right">{{ totals[index].tariff_formatted }}</td>
                                                <td class="text-right">{{ totals[index].difference_salary_formatted }}</td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td>TOTAL GENERAL</td>
                                                <td>{{ quantityG }}</td>
                                                <td class="text-right">{{ salaryG }}</td>
                                                <td class="text-right">{{ tariffG }}</td>
                                                <td class="text-right">{{ differenceSalaryG }}</td>
                                            </tr>
                                        </template>
                    
                                    </b-table>

                                </b-card-body>
                            </b-card>

                            <b-card no-body class="card-graphic-quantities">
                                <b-card-header>
                                    <h5 class="mb-0 text-white"><b>GRÁFICA DE PUESTOS DIFERENCIA SALARIAL ({{ period.toUpperCase() }})</b></h5>
                                </b-card-header>
                                <b-card-body class="pt-2">
                                    <b-row>
                                        <b-col xs="12" md="8">
                                            <vue-apex-charts
                                                ref="refFirstBarGraph"
                                                type="bar"
                                                height="420"
                                                :options="firstBarGraph.chartOptions"
                                                :series="firstBarGraph.series"
                                            />
                                        </b-col>
                                        <b-col xs="12" md="4">
                                            <json-excel :data="graphicQTableExport()" :name="getGraphicQTableExcelName()"></json-excel>

                                            <b-table
                                                striped
                                                hover
                                                responsive
                                                class="position-relative text-center table-graphic-quantities mb-0"
                                                :per-page="perPage2"
                                                :current-page="currentPage2"
                                                :items="items2"
                                                :fields="fields2"
                                                :sort-by.sync="sortBy2"
                                                :sort-desc.sync="sortDesc2"
                                                :sort-direction="sortDirection2"
                                            >

                                                <template #custom-foot>
                                                    <tr>
                                                        <td>TOTAL GENERAL</td>
                                                        <td>{{ quantityG }}</td>
                                                    </tr>
                                                </template>
                            
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>

                            <b-card no-body class="card-graphic-percents">
                                <b-card-header>
                                    <h5 class="mb-0 text-white"><b>GRÁFICA DE % INCLUMPLIMIENTO DE SUELDOS ({{ period.toUpperCase() }})</b></h5>
                                </b-card-header>
                                <b-card-body class="pt-2">
                                    <b-row>
                                        <b-col xs="12" md="8">
                                            <vue-apex-charts
                                                ref="refSecondBarGraph"
                                                type="bar"
                                                height="420"
                                                :options="secondBarGraph.chartOptions"
                                                :series="secondBarGraph.series"
                                            />
                                        </b-col>
                                        <b-col xs="12" md="4">
                                            <json-excel :data="graphicPTableExport()" :name="getGraphicPTableExcelName()"></json-excel>

                                            <b-table
                                                striped
                                                hover
                                                responsive
                                                class="position-relative text-center table-graphic-percents mb-0"
                                                :per-page="perPage3"
                                                :current-page="currentPage3"
                                                :items="items3"
                                                :fields="fields3"
                                                :sort-by.sync="sortBy3"
                                                :sort-desc.sync="sortDesc3"
                                                :sort-direction="sortDirection3"
                                            >
                            
                                            </b-table>
                                        </b-col>
                                    </b-row>
                                </b-card-body>
                            </b-card>

                        </template>

                        <b-alert show variant="danger" v-else>
                            <h4 class="alert-heading">LISTADO DE PROVEEDORES CON DIFERENCIA SALARIAL</h4>
                            <div class="alert-body">NO SE ENCONTRARON REGISTROS COINCIDENTES</div>
                        </b-alert>
    
                    </template>

                </b-card>
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from './download-excel';
    import moment from 'moment';
    import vSelect from 'vue-select';
    import axios from '@axios';
    import slug from 'slug';

    export default {
        components: {
            VueApexCharts,
            JsonExcel,
            vSelect
        },
        data() {
            return {
                btnFilterDisabled: false,

                isLoading: true,

                period: null,

                pageOptions: [10, 15, 20, 25, 30],
                currentPage: 1,

                sortBy: 'enumeration',
                sortDesc: false,
                sortDirection: 'asc',
                totalsG: [],
                fields: [],
                totals: [],
                items: [],

                // ====================== 
                
                filtersForm: {
                    year: null,
                    month: null,
                    providers: [],
                    positions: []
                },

                years: [],
                months: [],
                providers: [],
                positions: [],

                firstBarGraph: {
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            toolbar: {
                                show: false
                            }
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 4,
                                horizontal: true,
                                dataLabels: {
                                    position: 'bottom'
                                }
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                                colors: ['#FFFFFF']
                            },
                            formatter: function (val, opt) {
                                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val
                            },
                            offsetX: 0,
                            dropShadow: {
                                enabled: true
                            }
                        },
                        xaxis: {
                            categories: [],
                            labels: {
                                style: {
                                    colors: ['#FFFFFF']
                                }
                            }
                        },
                        yaxis: {
                            labels: {
                                show: false
                            }
                        },
                        legend: { 
                            show: false 
                        },
                        stroke: {
                            width: 4
                        },
                        noData: { 
                            text: 'Sin datos...' 
                        }
                    },
                    series: [{
                        name: 'Cantidad',
                        data: []
                    }]
                },

                secondBarGraph: {
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            toolbar: {
                                show: false
                            },
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 4,
                                horizontal: true,
                                dataLabels: {
                                    position: 'bottom'
                                }
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            textAnchor: 'start',
                            style: {
                                colors: ['#FFFFFF']
                            },
                            formatter: function (val, opt) {
                                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + "%"
                            },
                            offsetX: 0,
                            dropShadow: {
                                enabled: true
                            }
                        },
                        xaxis: {
                            categories: [],
                            labels: {
                                style: {
                                    colors: '#FFFFFF'
                                }
                            }
                        },
                        yaxis: {
                            labels: {
                                show: false
                            }
                        },
                        legend: { 
                            show: false 
                        },
                        stroke: {
                            width: 4
                        },
                        noData: { 
                            text: 'Sin datos...' 
                        }
                    },
                    series: [{
                        name: 'Porcentaje',
                        data: []
                    }]
                },

                // ====================== 

                perPage2: 50000,
                currentPage2: 1,

                items2: [],
                fields2: [
                    {
                        key: 'provider',
                        label: 'PROVEEDOR',
                        sortable: true
                    },
                    {
                        key: 'quantity',
                        label: 'CANTIDAD',
                        sortable: true
                    }
                ],

                sortBy2: '',
                sortDesc2: false,
                sortDirection2: 'asc',

                // ======================

                perPage3: 50000,
                currentPage3: 1,

                items3: [],
                fields3: [
                    {
                        key: 'provider',
                        label: 'PROVEEDOR',
                        sortable: true
                    },
                    {
                        key: 'percent',
                        label: '%',
                        sortable: true
                    }
                ],

                sortBy3: '',
                sortDesc3: false,
                sortDirection3: 'asc'
            }
        },
        computed: {
            quantityG() {
                const quantityTotal = this.totals.reduce((accumulator, object) => {
                    return accumulator + object.quantity;
                }, 0);

                return quantityTotal;
            },
            salaryG() {
                const salaryTotal = this.totals.reduce((accumulator, object) => {
                    return accumulator + object.salary;
                }, 0);

                return `S/ ${salaryTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
            },
            tariffG() {
                const tariffTotal = this.totals.reduce((accumulator, object) => {
                    return accumulator + object.tariff;
                }, 0);

                return `S/ ${tariffTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
            },
            differenceSalaryG() {
                const differenceSalaryTotal = this.totals.reduce((accumulator, object) => {
                    return accumulator + object.difference_salary;
                }, 0);

                return `S/ ${differenceSalaryTotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
            }
        },
        async beforeMount() {
            await this.getProviders();
            await this.getPositions();

            const currentYear = parseInt(moment().format('YYYY'));

            const currentSubtractYear = parseInt(moment().subtract(1, 'months').format('YYYY'));
            const currentSubtractMonth = parseInt(moment().subtract(1, 'months').format('MM'));

            this.years = [];
            this.months = [];
            
            for (var i = 2023; i <= currentYear; i++)
            {
                this.years.push({ id: i, name: i });
            }

            this.months.push({ id: 1, name: 'ENERO' });
            this.months.push({ id: 2, name: 'FEBRERO' });
            this.months.push({ id: 3, name: 'MARZO' });
            this.months.push({ id: 4, name: 'ABRIL' });
            this.months.push({ id: 5, name: 'MAYO' });
            this.months.push({ id: 6, name: 'JUNIO' });
            this.months.push({ id: 7, name: 'JULIO' });
            this.months.push({ id: 8, name: 'AGOSTO' });
            this.months.push({ id: 9, name: 'SEPTIEMBRE' });
            this.months.push({ id: 10, name: 'OCTUBRE' });
            this.months.push({ id: 11, name: 'NOVIEMBRE' });
            this.months.push({ id: 12, name: 'DICIEMBRE' });

            this.filtersForm.year = this.years.find(year => year.id === currentSubtractYear);
            this.filtersForm.month = this.months.find(month => month.id === currentSubtractMonth);

            await this.filterData();
        },
        methods: {
            async getProviders(){
                const response = await axios.get('/providers/select')
                this.providers = response.data;
            },
            async getPositions(){
                const response = await axios.get('/positions/select-2')
                this.positions = response.data;
            },
            async getDataTable (){

                this.btnFilterDisabled = true;
                this.isLoading = true;

                const year = this.filtersForm.year.id;
                const month = this.filtersForm.month.id;
                
                const providers = this.filtersForm.providers ? this.filtersForm.providers.map(item => item.id) : [];
                const positions = this.filtersForm.positions ? this.filtersForm.positions.map(item => item.id) : [];
                
                let response = await axios.get(`/reporteria/table-salary-difference?year=${year}&month=${month}&providers=${providers.join(',')}&positions=${positions.join(',')}`);
            
                const { period, fields, data, totals, graphicQCategories, graphicQSeries, graphicQTable, graphicPCategories, graphicPSeries, graphicPTable } = response.data;

                this.period = period;

                this.fields = fields;
                this.totals = totals;
                this.items = data;

                this.firstBarGraph.chartOptions.xaxis.categories = graphicQCategories;
                this.firstBarGraph.chartOptions.xaxis.categories.unshift('TOTAL GENERAL');

                this.firstBarGraph.series[0].data = graphicQSeries;
                this.firstBarGraph.series[0].data.unshift(this.quantityG);

                this.secondBarGraph.chartOptions.xaxis.categories = graphicPCategories;
                this.secondBarGraph.series[0].data = graphicPSeries;

                this.items2 = graphicQTable;
                this.items3 = graphicPTable;

                this.sortBy = 'enumeration';
                this.sortDesc = false;
                this.sortDirection = 'asc';

                this.sortBy2 = '';
                this.sortDesc = false;
                this.sortDirection2 = 'asc';

                this.sortBy3 = '';
                this.sortDesc3 = false;
                this.sortDirection3 = 'asc';

                this.btnFilterDisabled = false;
                this.isLoading = false;

            },
            async filterData (){
                await this.getDataTable();
            },
            getExcelName(provider, withDetails = false, general = false) {      
                let fileName = `${slug(provider, '_')}_diferencia_salarial_${ this.filtersForm.month.name.toLowerCase() }_${ this.filtersForm.year.id }.xls`;
                
                if (withDetails) {
                    fileName = fileName.replace('.xls', '_detallado.xls')
                }

                if (general) {
                    fileName = fileName.replace('.xls', '_general.xls')
                }

                return fileName;
            },
            getGraphicQTableExcelName() {
                return `diferencia_salarial_grafica_cantidades_${ this.filtersForm.month.name.toLowerCase() }_${ this.filtersForm.year.id }.xls`;
            },
            getGraphicPTableExcelName() {
                return `diferencia_salarial_grafica_porcentajes_${ this.filtersForm.month.name.toLowerCase() }_${ this.filtersForm.year.id }.xls`;
            },
            removeCurrencyFormat (item, currency = 'PEN') {
                if (item) {
                    if (currency === 'PEN') item = item.replace('S/ ', '');

                    if (currency === 'USD') item = item.replace('$ ', '');

                    item = item.replaceAll(',', '');

                    return item
                }

                return '-'
            },
            tableExport(provider, items, totals) {
                let array = [];

                let data = [...items];

                data[items.length] = totals;
                data[items.length + 1] = {
                    role: 'TOTAL GENERAL',
                    quantity: this.quantityG,
                    salary_formatted: this.salaryG,
                    tariff_formatted: this.tariffG,
                    difference_salary_formatted: this.differenceSalaryG
                }; 

                for (let a = 0; a < data.length; a++)
                {
                    let objBody = {
                        'ITEM': ([data.length - 1, data.length].includes(a + 1)) ? null : (a + 1),
                        'PROVEEDOR': ([data.length - 1, data.length].includes(a + 1)) ? null : provider,
                        'PUESTO/CARGO': data[a]['role'],
                        'CANTIDAD': data[a]['quantity'],
                        'SUELDO': this.removeCurrencyFormat(data[a]['salary_formatted']),
                        'TARIFARIO': this.removeCurrencyFormat(data[a]['tariff_formatted']),
                        'DIFERENCIA': this.removeCurrencyFormat(data[a]['difference_salary_formatted'])
                    };

                    array.push(objBody);
                }

                return array;
            },
            tableDetailsExport(provider, items, totals) {

                let array = [];

                let data = [...items];

                data[items.length] = totals;
                data[items.length + 1] = {
                    role: 'TOTAL GENERAL',
                    quantity: this.quantityG,
                    salary_formatted: this.salaryG,
                    tariff_formatted: this.tariffG,
                    difference_salary_formatted: this.differenceSalaryG
                }; 

                for (let a = 0; a < data.length; a++)
                {
                    let objBody = {
                        'ITEM': ([data.length - 1, data.length].includes(a + 1)) ? null : (a + 1),
                        'PROVEEDOR': ([data.length - 1, data.length].includes(a + 1)) ? null : provider,
                        'NOMBRE COMPLETO': data[a]['fullName'],
                        'PUESTO/CARGO': data[a]['role'],
                        'CANTIDAD': data[a]['quantity'],
                        'SUELDO': this.removeCurrencyFormat(data[a]['salary_formatted']),
                        'TARIFARIO': this.removeCurrencyFormat(data[a]['tariff_formatted']),
                        'DIFERENCIA': this.removeCurrencyFormat(data[a]['difference_salary_formatted'])
                    };

                    array.push(objBody);
                }

                return array;
            },
            tableGeneralExport(provider, items) {

                let array = [];

                let data = [...items];

                for (let a = 0; a < data.length; a++)
                {
                    let objBody = {
                        'ITEM': (a + 1),
                        'PROVEEDOR': provider,
                        'NOMBRE COMPLETO': data[a]['fullName'],
                        'PUESTO/CARGO': data[a]['role'],
                        'CANTIDAD': data[a]['quantity'],
                        'SUELDO': this.removeCurrencyFormat(data[a]['salary_formatted']),
                        'TARIFARIO': this.removeCurrencyFormat(data[a]['tariff_formatted']),
                        'DIFERENCIA': this.removeCurrencyFormat(data[a]['difference_salary_formatted'])
                    };

                    array.push(objBody);
                }

                return array;
            },
            graphicQTableExport() {
                let array = [];

                let data = [...this.items2];

                data[this.items2.length] = {
                    provider: 'TOTAL GENERAL',
                    quantity: this.quantityG
                }; 

                for (let a = 0; a < data.length; a++)
                {
                    let objBody = {
                        'PROVEEDOR': (a + 1 === data.length) ? null : data[a]['provider'],
                        'CANTIDAD': data[a]['quantity'],
                    };

                    array.push(objBody);
                }

                return array;
            },
            graphicPTableExport() {
                let array = [];

                for (let a = 0; a < this.items3.length; a++)
                {
                    let objBody = {
                        'PROVEEDOR': this.items3[a]['provider'],
                        '%': this.items3[a]['percent'],
                    };

                    array.push(objBody);
                }

                return array;
            }
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss" scoped>

    .card-salary-difference-item,
    .card-graphic-quantities,
    .card-graphic-percents {

        .card-header {
            background-color: var(--primary);
            padding: 1rem 1.5rem;
        }

        .card-body {
            border: 1px solid var(--primary);
            border-radius: 0 0 6px 6px;
        }

    }

    .card-salary-difference-item:nth-last-child(1) {
        margin-bottom: 0;
    }

</style>

<style>

    .table-salary-difference .b-table thead tr th:nth-child(2) {
        text-align: left !important;
    }

    .table-salary-difference .b-table tbody tr td:nth-child(2),
    .table-salary-difference .b-table tfoot tr td:nth-child(2) {
        text-align: left !important;
    }

    .table-salary-difference .b-table thead tr th:nth-child(6),
    .table-salary-difference .b-table tfoot tr th:nth-child(6) {
        text-align: right !important;
    }

    .table-salary-difference .b-table tbody tr td:nth-child(6),
    .table-salary-difference .b-table tfoot tr td:nth-last-child(1) {
        text-align: right !important;
        color: var(--danger);
        font-weight: bold;
    }

    .table-salary-difference .b-table tfoot tr td {
        font-weight: bold;
        color: var(--primary);
    }

    .table-graphic-quantities .b-table tbody tr td:nth-child(1),
    .table-graphic-quantities .b-table tfoot tr td:nth-child(1),
    .table-graphic-percents .b-table tbody tr td:nth-child(1) {
        text-align: left !important;
    }

    .table-graphic-quantities .b-table tfoot tr td {
        font-weight: bold;
        color: var(--primary);
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>